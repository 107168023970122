<template>
  <div>
    <h1>{{ $t("keep-everything-about-process-in-one-place") }}</h1>
    <b-row align-v="center" class="mt-5 pb-5">
   
      <b-col cols="7">
        <div class="img-wrapper">
            <img
            muted
            autoplay
            loop
            class="video mx-auto img-radius img-fluid d-block"
            src="@/app/assets/img/diagram-group.png"
          />
        </div>
      </b-col>
      <b-col class="">
        <div>
          <h2 class="text-left">{{ $t("create-a-process-group") }}</h2>
          <p class="text-left hugep">
            {{ $t("with-storm-you-can-group") }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mt-5">
      <b-col class="">
        <div>
          <h2 class="text-left">{{ $t("create-a-models") }}</h2>
          <p class="text-left hugep">
            {{ $t("with-storm-you-can") }}
          </p>
        </div>
      </b-col>
      <b-col cols="7">
        <div class="img-wrapper">
          <video
            muted
            autoplay
            loop
            class="video mx-auto img-radius img-fluid d-block"
            src="@/app/assets/img/mp4/keepOnePlace_1.mp4"
          />
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mt-5 pt-5">
      <b-col cols="7">
        <div class="img-wrapper">
          <video
            muted
            autoplay
            loop
            class="video mx-auto img-radius img-fluid d-block"
            src="@/app/assets/img/mp4/keepOnePlace_2.mp4"
          />
        </div>
      </b-col>
      <b-col class="">
        <div>
          <h2 class="text-left">{{ $t("create-a-dictionaries") }}</h2>
          <p class="text-left hugep">
            {{ $t("instead-of-data-object") }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mt-5 pt-5">
      <b-col class="">
        <div>
          <h2 class="text-left">{{ $t("place-to-folder") }}</h2>
          <p class="text-left hugep">
            {{ $t("mark-processes-with-tag") }}
          </p>
        </div>
      </b-col>
      <b-col cols="7">
        <div class="img-wrapper">
          <img
            muted
            autoplay
            loop
            class="video mx-auto img-radius img-fluid d-block"
            src="@/app/assets/img/keepOnePlace_3.png"
          />
        </div>
      </b-col>
    </b-row>
    <b-row align-v="center" class="mt-5 pt-5">
      <b-col cols="7">
        <div class="img-wrapper">
          <img
            muted
            autoplay
            loop
            class="video mx-auto img-radius img-fluid d-block"
            src="@/app/assets/img/keepOnePlace_44.png"
          />
        </div>
      </b-col>
      <b-col class="">
        <div>
          <h2 class="text-left">{{ $t("share-process") }}</h2>
          <p class="text-left hugep">
            {{ $t("coworker-will-be-able") }}
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "FrontendKeepOneSpace",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.img-radius {
  border: 2px none #1c6ea4;
}

.img-wrapper {
  -webkit-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 6px 8px 27px 0px rgba(34, 60, 80, 0.2);
  border: 2px none #1c6ea4;
  border-radius: 0.75rem;
}

video {
  clip-path: inset(7px 7px);
}

.hugep {
  font-size: 20px;
}
</style>
